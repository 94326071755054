import { render, staticRenderFns } from "./pregnant_tips.vue?vue&type=template&id=2076a92a&scoped=true&"
import script from "./pregnant_tips.vue?vue&type=script&lang=js&"
export * from "./pregnant_tips.vue?vue&type=script&lang=js&"
import style0 from "./pregnant_tips.vue?vue&type=style&index=0&id=2076a92a&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2076a92a",
  null
  
)

export default component.exports