<template>
    <div>
        <el-tabs type="border-card" v-if="pregnancyData.length" style="max-height: 600px; overflow-y: auto;">
            <el-tab-pane v-for="(week, index) in pregnancyData" :key="index" :label="week.label">
                <div>
                    <h3>孕期症状</h3>
                    <p>{{ week.symptoms }}</p>
                </div>
                <div>
                    <h3>胎儿发育</h3>
                    <p>{{ week.size }}</p>
                </div>
                <div>
                    <h3>腹部大小</h3>
                    <p>{{ week.belly_size }}</p>
                </div>
                <div>
                    <h3>食谱推荐</h3>
                    <p>{{ week.diet }}</p>
                </div>
                <div>
                    <h3>注意事项</h3>
                    <p>{{ week.tips }}</p>
                </div>
            </el-tab-pane>
        </el-tabs>
    </div>
</template>

<script>
import pregnancyData from "@/assets/dataFile/pregnancyData.json";

export default {
    data() {
        return {
            pregnancyData: [],
        };
    },
    created() {
        // 从静态 JSON 文件中加载孕期数据
        this.loadPregnancyData();
    },
    methods: {
        loadPregnancyData() {
            this.pregnancyData = pregnancyData;
        },
    },
};
</script>

<style scoped>
.el-tabs {
    margin: 20px;
}

.el-tab-pane {
    padding: 10px;
}

h3 {
    margin-top: 15px;
    color: #333;
}
</style>
